.modal_window_desk {
  max-width: 90%;
  padding: 3.6rem 4.7rem 3.5rem 6rem;

  &.light {
    background: #ffffff;
    .inner_container {
      .sticky {
        background: #ffffff;
        .custom_close_btn {
          svg {
            path {
              stroke: #000000;
            }
          }
        }
        .title {
          color: #000000;
        }
        .right {
          .sum {
            .disc {
              color: #9b9fa8;
            }
          }
        }
      }
      .subtitle {
        color: #000000;
      }
    }
  }
}

.close_btn {
  display: none !important;
}

.custom_close_btn {
  svg {
    path {
      stroke: #fff;
    }
  }
}
.sticky {
  position: sticky;
  top: -3rem;
  z-index: 1;
  background: #242b33;
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.title {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #f2f2f2;
  margin-left: 5rem;
  flex-shrink: 0;
}
.save_btn {
  width: fit-content;
  margin-left: auto;
  button {
    width: 18rem;
    height: 6.5rem;
    font-size: 1.8rem;
  }
}

.item {
  margin-top: 3.5rem;
}

.row_title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 130%;
  color: #fff;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.row_item {
  width: 15rem;
  margin-right: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #525d69;
  border-radius: 0.5rem;
  margin-top: 1rem;

  .item_title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 130%;
    color: #fff;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 4.5rem;
  }
  .item_cost {
    color: rgba(255, 255, 255, 0.63);
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 130%;
    margin-top: 0.5rem;
  }
  .switch_container {
    padding-left: 0;
    margin-top: 1rem;
  }
  .switch {
    background: #525d69 !important;
    border-color: #525d69 !important;
  }
}

@media (orientation: portrait), (max-width: 768px) {
  .modal_window_desk {
    max-width: 100%;
    padding: 3rem;
    .close_btn {
      top: 0.75rem;
      position: sticky;
      z-index: 2;
    }
  }
  .sticky {
    position: sticky;
    top: -3rem;
    z-index: 1;
    background: #242b33;
    flex-wrap: wrap;
  }
  .title {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 440px) {
  .sticky {
    .right {
      margin-left: 0;
      justify-content: space-between;
      width: 100%;
    }
  }
}
