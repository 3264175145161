.input__item {
  border: none;
  position: relative;
  border-radius: 1rem;
  padding: 2.6rem 2.5rem 1rem 2.5rem;
  background: #ffffff;
  border: 0.1rem solid #eeeeee;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  &.wrong {
    border: 0.1rem solid #ff3e6c;
  }

  .little_text {
    position: absolute;
    top: 1.8rem;
    left: 2.5rem;
    // z-index: 3;
    font-size: 1.4rem;
    color: #999999;
    transition: all 0.2s ease-in-out;
  }

  .input {
    font-size: 1.6rem;
    line-height: 1.9rem;
    border: none;
    color: #2d2d2d;
    width: 100%;
    padding: 0;
    resize: none;
    overflow: hidden;
    font-family: 'Inter';
  }

  .icon {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1.5rem;
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);

    img {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
    }
  }

  &.with_icon {
    padding: 2.6rem 2.5rem 1rem 5rem;
    .little_text {
      left: 5rem;
    }
  }

  &.focus {
    .little_text {
      font-size: 1.2rem;
      top: 0.6rem;
    }
  }
}
