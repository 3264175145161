.universalCustomSelect-container {
  padding: 1rem;
}
.universalCustomSelect__control {
  font-size: 1.6rem;
  line-height: 2rem;
  border: none !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
  outline: none;
  padding: 0;
  font-family: 'Inter' !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;

  .universalCustomSelect-placeholder {
    margin: 0;
  }

  .universalCustomSelect__value-container {
    padding: 0 1.5rem;
  }

  .universalCustomSelect__input-container {
    cursor: text;
    padding: 0;
    font-family: 'Inter';
    margin: 0;

    input {
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 1.4rem !important;
      line-height: 1.6rem !important;
    }
  }
  .universalCustomSelect__indicator-separator {
    display: none;
  }
  .universalCustomSelect__indicator {
    cursor: pointer;
    background: #eeeeee;
    padding: 1rem;
  }
  .universalCustomSelect__clear-indicator {
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
}
.universalCustomSelect__menu {
  font-size: 1.6rem;
  line-height: 2rem;
  overflow: hidden;
  border-radius: 1rem !important;
  .universalCustomSelect__option {
    cursor: pointer;
  }
  .universalCustomSelect__menu-list {
    padding: 0;
  }
  .universalCustomSelect__option--is-focused {
    background-color: #fbfbfb;
  }
  .universalCustomSelect__option--is-selected {
    background-color: #ff7a00;
    color: #fff;
  }
}

.grayUniversalCustomSelect-container {
  padding: 1rem;
}
.grayUniversalCustomSelect__control {
  font-size: 1.6rem;
  line-height: 2rem;
  border: none !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
  outline: none;
  padding: 0;
  font-family: 'Inter' !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
  background: #f8f8f8 !important;
  min-height: 2.4rem !important;

  .grayUniversalCustomSelect-placeholder {
    margin: 0;
  }

  .grayUniversalCustomSelect__value-container {
    // padding: 0 1.5rem;
    padding: 0;
  }

  .grayUniversalCustomSelect__placeholder {
    color: #000;
  }

  .grayUniversalCustomSelect__single-value {
    color: #000;
    font-weight: bold;
  }

  .grayUniversalCustomSelect__input-container {
    cursor: text;
    padding: 0;
    font-family: 'Inter';
    margin: 0;

    input {
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 1.4rem !important;
      line-height: 1.6rem !important;
      color: #000 !important;
    }
  }
  .grayUniversalCustomSelect__indicator-separator {
    display: none;
  }
  .grayUniversalCustomSelect__indicators {
    > div {
      background: #f8f8f8 !important;
    }
  }
  .grayUniversalCustomSelect__indicator {
    cursor: pointer;
    background: #f8f8f8;
    padding: 1rem;
  }
  .grayUniversalCustomSelect__clear-indicator {
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
}
.grayUniversalCustomSelect__menu {
  font-size: 1.6rem;
  line-height: 2rem;
  overflow: hidden;
  border-radius: 1rem !important;
  box-sizing: border-box;
  width: 85% !important;
  .grayUniversalCustomSelect__option {
    cursor: pointer;
  }
  .grayUniversalCustomSelect__menu-list {
    padding: 0;
  }
  .grayUniversalCustomSelect__option--is-focused {
    background-color: #fbfbfb;
  }
  .grayUniversalCustomSelect__option--is-selected {
    background-color: #ff7a00;
    color: #fff;
  }
}
