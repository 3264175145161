.btn_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.alignUnderInput {
  width: fit-content;
  margin-left: calc(20% + 1.5rem);
  margin-top: 2rem;
}
.loader {
  margin: 0;
}
.btn {
  width: 14rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 2rem;
  border-radius: 1rem;
  font-weight: 600;
  border: none;
  font-family: 'Inter';
  padding: 0;

  img {
    width: 2.4rem;
  }

  &.with_icon {
    img {
      margin-right: 1rem;
    }
  }

  &.gray {
    background-color: #e7e7e7;
    color: #303030;
  }
  &.dark_gray {
    background-color: #e5e5e5;
    color: #000000;
  }

  &.red {
    background-color: #ff2b2b;
    color: #ffffff;
  }
  &.green {
    background-color: #24ca49;
    color: #ffffff;
  }
  &.orange {
    background-color: #ff9900;
    color: #ffffff;
  }
  &.white {
    background: #ffffff;
    box-shadow: 0 0.4rem 1rem rgba(200, 200, 200, 0.42);
    border-radius: 3rem;
    color: #000;
  }
  &.white_without_shadow {
    background: #ffffff;
    border-radius: 3rem;
    color: #000;
  }
  &.yellow {
    background: #fae000;
    color: #000;
  }
  &.fiol {
    background: #4200ff;
    color: #fff;
  }
  &.fiol_with_white_background {
    border: 0.1rem solid #4200ff;
    background: #fff;
    color: #4200ff;
  }
  &.large {
    width: 100%;
    height: 6rem;
    font-size: 2rem;
    line-height: 2.9rem;
    font-weight: bold;
  }
  &.transparent {
    background: transparent;
    color: #ffffff;
  }
  &.withoutShadow {
    box-shadow: none;
  }

  &.isLoading {
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
