.input_container {
  input {
    text-align: center;
  }
}

.btn {
  > button {
    width: 100%;
    height: 6rem;
  }
}

.top_row {
  display: flex;
  > div,
  label {
    width: 20%;
    margin-bottom: 0;
    &:last-child {
      > div {
        margin-right: 0;
      }
    }
    > div {
      height: 100%;
      padding-left: 0;
      margin-right: 1rem;
      input {
        height: 100%;
      }
    }
  }
}

.bottom_container {
  margin-top: 2rem;
  .title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.1rem;
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    svg {
      width: 3.5rem;
      height: auto;
      margin-right: 1rem;
      cursor: pointer;
      &:hover {
        path {
          fill: #4200ff;
        }
      }
    }
  }
  .items {
    // display: flex;
    // flex-wrap: wrap;
    column-count: 5;

    .item {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.1rem;
      // margin-top: 2.5rem;
      // width: calc(20% - 1rem);
      margin-bottom: 2.5rem;
      // margin-right: 1rem;
      color: #000;
      cursor: pointer;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &.active,
      &:hover {
        color: #4200ff;
      }
    }
  }
}
