.title {
  text-align: center;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #000;
}
.content {
  margin-top: 3rem;
}

.btn {
  width: 100%;
  height: 4rem;
}
