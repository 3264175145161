.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 4rem;
}
.left,
.right {
  width: 6rem;
  height: 6rem;
  flex-shrink: 0;
}
.left {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 4rem;
    height: auto;
  }
}
.center {
  flex: 1;
  text-align: center;
  font-size: 2.4rem;
  line-height: 130%;
  font-weight: bold;
}
