.container {
  display: flex;
  flex-wrap: wrap;
  // padding: 4rem;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  .item {
    // &:nth-child(6n) {
    //   margin-right: 0;
    // }
  }
}
.item {
  width: calc(100% / 6 - 1rem);
  padding-bottom: calc(100% / 6 - 1rem);
  position: relative;
  background: #84ab72;
  border: 0.1rem solid #000;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 0;
  cursor: pointer;
  &.closed {
    background: #ff5151;
  }
  &.slow {
    background: #cecb2b;
  }
}
.name {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.8rem;
  line-height: 130%;
  color: #fff;
  box-sizing: border-box;
  text-align: left;
}
.paragraph {
  margin-top: 1rem;
  margin-bottom: 0;
}
.time_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  .input_container {
    div {
      padding: 0;
    }
  }
  .btn_container {
  }
}
