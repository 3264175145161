.modal_window_desk {
  background: #242b33;
  border-radius: 2rem;
  padding: 6rem;
  width: calc(100vw - 10rem);
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  // transform: translate(-50%, -50%);
  // position: absolute;
  box-sizing: border-box;
  // min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;

  .modal_close_button {
    position: absolute;
    cursor: pointer;
    right: -3rem;
    top: -3rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
    &.black_btn {
      svg {
        fill: #000;
        path {
          fill: #000;
          stroke: #000;
        }
      }
    }
  }
}
.white {
  background: #fff !important;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (orientation: portrait), (max-width: 768px) {
  .modal_window_desk {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 6rem 3rem;
    overflow: auto;
    transform: translate3d(0, 0, 0);

    .modal_close_button {
      right: -1rem;
      top: -4rem;
    }
  }
}
