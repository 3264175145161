.light {
  background: #ffffff;

  .title {
    color: #000000;
  }
  .input_block {
    background: #f8f8f8;
    .input_title {
      color: #000000;
    }
    input {
      background: #f8f8f8;
      color: #000000;
    }
  }
  .card_info {
    .info_item {
      color: #000;
    }
  }
}

.light_btn {
  svg {
    path {
      stroke: #000000;
    }
  }
}

.inner_container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 42rem;
  margin: 2rem auto 0;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #fff;
}

.btn_container {
  width: 100%;
  height: 6.4rem;
  margin-top: 2.5rem;
  button {
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

.error {
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8rem;
  background: #ff2b2b;
  margin-top: 2rem;
  padding: 0.8rem 2rem 1rem 2rem;
  border-radius: 3rem;
}

.input_container {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.check {
  display: flex;
  align-items: center;
  width: 20rem;
  &_title {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    width: unset;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  label {
    padding-left: 1.5rem;
    width: fit-content;
  }
}

.loader {
  margin: 0 auto;
  padding: 2rem;
}

.extra_user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  .extra_user_name {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
  .extra_btn_container {
    width: unset;
  }
}
