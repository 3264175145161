.loader_container {
  width: fit-content;
  margin: 7rem auto;
  padding: 1rem 0;
  &.without_margin {
    margin: 0 auto;
  }
}

.app_loader {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  svg {
    width: 16rem;
    height: 10rem;
  }
}
