.container {
  display: flex;
  padding: 2.5rem 3rem;
  background-color: #f8f8f8;
  border-radius: 2rem;
  margin-top: 3rem;
  box-sizing: border-box;
  border: 2px solid #f8f8f8;
  &.active {
    border-color: #4200ff;
  }
}
.left {
  flex: 1;
}
.right {
  width: fit-content;
  flex-shrink: 0;
}
.title {
  font-size: 2.8rem;
  line-height: 130%;
  color: #000;
  font-weight: bold;
}
.subtitle {
  font-size: 2rem;
  line-height: 130%;
  color: #5b5b5b;
  margin-top: 0.5rem;
}
.cost {
  font-size: 2rem;
  line-height: 130%;
  color: #999999;
  margin-top: 0.5rem;
}

.number {
  padding: 1.2rem 1.5rem 0.8rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #000;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 130%;
  color: #000;
}
