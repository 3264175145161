.modal {
  max-width: 100%;
  height: 100%;
  background-color: #fff;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #fff;
}

.inner_container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.light_btn {
  svg {
    path {
      stroke: #000000;
    }
  }
}

.times {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 2rem auto 0;
  span {
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 1rem;
  }
  input {
    background: #3e4650;
    border-radius: 1rem;
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    border: none;
    width: 10rem !important;
    height: 4rem;
    text-align: center;
  }
}
.btn {
  margin-top: 3rem;
  button {
    width: 26rem;
    height: 6rem;
  }
}
