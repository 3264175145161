.container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
}
.scan_container {
  background-color: #fff;
  min-height: 100%;
  position: relative;
}
.video_container {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.photo_btn_container {
  position: absolute;
  bottom: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoBtn {
  left: 0;
  margin: 0 auto;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: #fae000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-sizing: border-box;
  padding: 0;
  svg {
    width: 4rem;
    height: auto;
  }
}
.preview_container {
  width: 100%;
  height: 50rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.number_container {
  position: relative;
  top: -6rem;
  margin: 0 auto;
  max-width: 56rem;
  padding: 0 2rem;
  box-sizing: border-box;
}
.number {
  border: 2px solid #000;
  border-radius: 1rem;
  font-size: 6rem;
  padding: 2rem;
  line-height: 130%;
  text-align: center;
  background-color: #fff;
}
.input {
  font-size: 6rem;
  line-height: 130%;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  margin: 0;
}

.clients_container {
  padding: 0 2rem;
  max-width: 56rem;
  padding: 0 2rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 6rem;
}
.subtitle {
  font-size: 2.4rem;
  line-height: 130%;
  font-weight: bold;
  color: #000;
}
.btn_container {
  position: sticky;
  bottom: 3rem;
  left: 2rem;
  width: calc(100% - 4rem);
  > button,
  a {
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
    height: 10rem;
    font-size: 2rem;
  }
}
