.container {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;

  .checkbox {
    width: 2rem;
    height: 2rem;
    box-sizing: border-box;
    border: 0.1rem solid #f0f0f0;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    overflow: hidden;
    flex-shrink: 0;
    &.active {
      background-color: #0290ea;
      svg {
        width: 1.4rem;
      }
    }
  }
}
