.modal_window_desk {
  max-width: 90%;
  padding: 3.6rem 4.7rem 3.5rem 6rem;

  &.light {
    background: #ffffff;
    .inner_container {
      .sticky {
        background: #ffffff;
        .custom_close_btn {
          svg {
            path {
              stroke: #000000;
            }
          }
        }
        .title {
          color: #000000;
        }
        .right {
          .sum {
            .disc {
              color: #9b9fa8;
            }
          }
        }
      }
      .subtitle {
        color: #000000;
      }
    }
  }
}
.close_btn {
  display: none !important;
}
.custom_close_btn {
  svg {
    path {
      stroke: #fff;
    }
  }
}
.sticky {
  position: sticky;
  top: -3rem;
  z-index: 1;
  background: #242b33;
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.title {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #f2f2f2;
  margin-left: 5rem;
}
.edit_title {
  margin-left: 3.5rem;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 115%;
  color: #d4d4d4;
  cursor: pointer;
}
.subtitle {
  font-weight: 600;
  margin-top: 1.5rem;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #ffffff;
}
.adds_container {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

.orange_btn_container {
  width: auto;
  // margin-right: 5rem;
}

.orange_btn {
  background: #ff7a00 !important;
  width: 17.8rem;
  height: 6.5rem;
  font-size: 1.6rem;
  line-height: 2rem;

  img {
    // width: 2rem;
    height: 1.5rem;
    order: 2;
    margin-left: 1rem;
    margin-right: 0 !important;
    transform: rotate(180deg);
  }
  span {
    order: 1;
  }
}
.right {
  display: flex;
  align-items: center;
  margin-left: auto;
  .sum {
    margin-right: 3.6rem;
    text-align: right;
    flex-shrink: 0;
    .price {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 130%;
      color: #ff9900;
    }

    .disc {
      font-style: normal;
      font-weight: 300;
      font-size: 1.3rem;
      line-height: 100%;
      color: rgba(255, 255, 255, 0.63);
    }
  }
}

.planshet {
  display: none;
}

@media (orientation: portrait), (max-width: 768px) {
  .modal_window_desk {
    max-width: 100%;
    padding: 3rem;
    .close_btn {
      top: 0.75rem;
      position: sticky;
      z-index: 2;
    }
  }
  .sticky {
    position: sticky;
    top: -3rem;
    z-index: 1;
    background: #242b33;
    flex-wrap: wrap;
  }
  .title {
    font-size: 2.2rem;
  }
  .subtitle {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
  .adds_container {
    gap: 1rem;

    &.switch_container {
      gap: unset;
      > div {
        display: flex !important;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
        margin-bottom: 2rem;
        border: none;
      }
    }
  }
  .planshet {
    display: block;
  }
  .right {
    .sum {
      margin-right: 2.6rem;
      .price {
        font-size: 1.8rem;
      }
      .disc {
        font-size: 1.1rem;
      }
    }
  }
  .orange_btn {
    width: 12.6rem;
    height: 4.5rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

@media screen and (max-width: 440px) {
  .sticky {
    .right {
      margin-left: 0;
      justify-content: space-between;
      width: 100%;
    }
  }
}
