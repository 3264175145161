.container {
  z-index: 9999999999 !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999999;
  .toast {
    width: fit-content !important;
    padding: 0;
    box-shadow: none;
    background-color: rgba(2, 2, 2, 0.79);
    border-radius: 5rem;
    height: auto;
    min-height: auto;

    .toast_body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      padding: 1rem 3rem 1rem 3rem;
      > div {
        font-weight: 500;
        font-size: 2rem;
        color: #ffffff;
        line-height: 2.2rem;
        font-weight: bold;
        margin: 0;
        text-align: center;
        // white-space: nowrap;
        font-family: 'Inter';
      }
    }
  }
}
.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999999;
}

.notification_container {
  .title {
    font-size: 2.2rem;
    line-height: 130%;
    color: #000;
    font-weight: bold;
  }
  .message {
    font-size: 2rem;
    line-height: 130%;
    color: #000;
  }
  .info {
    font-size: 1.8rem;
    line-height: 130%;
    color: #000;
  }
}
