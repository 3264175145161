.info {
  display: flex;
  margin-bottom: 2rem;
  background: #efeeec;
  border-radius: 1rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #919191;
  padding: 1.5rem 2.5rem;
  .info_icon {
    margin-right: 2.5rem;
    flex-shrink: 0;
    svg {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}
