.modal {
  max-width: unset;
  width: calc(100% - 20rem);

  &.modal_mobile {
    width: 100%;
  }
}
.container {
  padding: 0 2.5rem;
  width: 100%;
  box-sizing: border-box;
  .title {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 110%;
    color: #000;
    text-align: center;
    margin-bottom: 3rem;
  }
  .btn_container {
    width: 33rem;
    margin: 1rem auto 0 auto;
    button {
      height: 7rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
}
