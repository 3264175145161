.table_container {
  //   margin: 2rem;
}

.head {
  display: flex;
  .column {
    background-color: #f5f3ef;
    font-size: 1.8rem;
    line-height: 130%;
  }
}
.row {
  display: flex;
  .column {
    background-color: #f5f3ef;
    font-size: 1.8rem;
    line-height: 130%;
    &.green {
      background-color: green;
    }
    &.red {
      background-color: red;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &.gray {
      background-color: lightgray;
    }
    &.yellow {
      background-color: yellow;
    }
  }
}

.column {
  width: 20rem;
  padding: 1rem;
  box-sizing: border-box;
  flex-shrink: 0;
  border-right: 0.1rem solid gray;
  border-bottom: 0.1rem solid gray;
}
.empty {
  font-size: 2rem;
}
