.container {
  display: flex;
  padding: 2.5rem 3rem;
  background-color: #fff;
  border-radius: 2rem;
  margin-top: 3rem;
  box-sizing: border-box;
  border: 1px solid #dedede;
  font-size: 2.4rem;
  line-height: 130%;
  color: #ababac;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 15rem;

  &.large {
    height: 30rem;
  }
}
