.item {
  display: inline-flex;
  align-items: flex-start;
  padding-right: 2rem;
  border-right: 1px solid rgba(255, 255, 255, 0.63);
  cursor: pointer;

  &.light {
    .item_info {
      .title {
        color: #000000;
      }
      .price {
        color: #9b9fa8;
      }
    }
    .switch {
      background-color: #dddada !important;
      border: 1px solid #dddada !important;

      span {
        background-color: #f8f8f8 !important;
      }
    }
  }
  .item_info {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 0.8rem;
    }
    .price {
      font-style: normal;
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 100%;
      color: rgba(255, 255, 255, 0.63);
    }
  }
  .switch {
    height: 2.9rem !important;
    width: 5.4rem !important;
    background-color: #525d69 !important;
    box-shadow: none !important;
    border: 1px solid #525d69 !important;
    margin-left: 3.5rem;
    pointer-events: none !important;
    span {
      height: 2.3rem !important;
      width: 2.3rem !important;
      left: 0.2rem !important;
      top: 0.2rem !important;
      cursor: pointer !important;
      background-color: #7a8a9c !important;
    }

    &.checked {
      span {
        left: 2.6rem !important;
        background-color: #24ca49 !important;
      }
    }
  }
}

@media (orientation: portrait), (max-width: 768px) {
  .item {
    // padding-left: 1rem;
    .item_info {
      .title {
        font-size: 1.4rem;
      }
      .price {
        font-size: 1.3rem;
        line-height: 100%;
      }
    }
    .switch {
      margin-left: 2.5rem;
    }
  }
}
