.light {
  background: #ffffff;

  .title {
    color: #000000;
  }
  .input_block {
    background: #f8f8f8;
    .input_title {
      color: #000000;
    }
    input {
      background: #f8f8f8;
      color: #000000;
    }
  }
  .card_info {
    .info_item {
      color: #000;
    }
  }
}

.light_btn {
  svg {
    path {
      stroke: #000000;
    }
  }
}

.inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42rem;
  margin: 0 auto;
  margin-top: 2rem;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #fff;
}

.input_block {
  background: #525d69;
  border-radius: 1rem;
  position: relative;
  padding: 1rem 4rem;
  margin-top: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  .input_title {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #fff;
  }
  input {
    color: #fff;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.6rem;
    background-color: #525d69;
    border: none;
    width: 100%;
    flex: 1;
  }
}
.btn_container {
  width: 100%;
  height: 6.4rem;
  margin-top: 2.5rem;
  button {
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

.card_info {
  width: 36rem;
  .info_item {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}
.error {
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8rem;
  background: #ff2b2b;
  margin-top: 2rem;
  padding: 0.8rem 2rem 1rem 2rem;
  border-radius: 3rem;
}
